import React, { useEffect, useContext } from "react";
import {
  FaceLivenessDetector,
  FaceLivenessDetectorCore,
} from "@aws-amplify/ui-react-liveness";
import { Loader } from "@aws-amplify/ui-react";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { GlobalContext } from "../../context/GlobalContext";
import "@aws-amplify/ui-react/styles.css";

// Liveness component

export const Liveness = ({}) => {
  const {
    isLoading,
    handleAnalysisComplete,
    fetchCredentials,
    sessionId,
    faceAuthResults,
  } = useContext(GlobalContext);

  const portugueseDictionary = {
    startScreenBeginCheckText: "Iniciar verificação",
    goodFitAltText: "Boa adaptação",
    photosensitivityWarningBodyText: "Aviso de fotossensibilidade",
    photosensitivityWarningHeadingText: "Aviso de fotossensibilidade",
    photosensitivityWarningInfoText:
      "Aviso: Este processo pode conter flashes de luz que podem causar desconforto em pessoas com fotossensibilidade.",
    photosensitivityWarningLabelText: "Aviso de fotossensibilidade",
    tooFarCaptionText: "Muito longe",
    tooFarAltText: "Muito longe",
    recordingIndicatorText: "Gravando",
    cancelLivenessCheckText: "Cancelar verificação de vivacidade",
    errorLabelText: "Erro",
    connectionTimeoutHeaderText: "Tempo de conexão esgotado",
    connectionTimeoutMessageText: "A conexão demorou muito para responder.",
    timeoutHeaderText: "Tempo esgotado",
    timeoutMessageText: "A operação demorou muito para responder.",
    faceDistanceHeaderText: "Distância do rosto",
    faceDistanceMessageText: "Por favor, aproxime seu rosto.",
    multipleFacesHeaderText: "Múltiplos rostos detectados",
    multipleFacesMessageText:
      "Por favor, certifique-se de que apenas um rosto esteja visível.",
    clientHeaderText: "Erro do cliente",
    clientMessageText: "Ocorreu um erro no cliente.",
    serverHeaderText: "Erro do servidor",
    serverMessageText: "Ocorreu um erro no servidor.",
    landscapeHeaderText: "Modo paisagem detectado",
    landscapeMessageText: "Por favor, segure o dispositivo no modo retrato.",
    portraitMessageText: "Modo retrato detectado",
    tryAgainText: "Tente novamente",
    hintCenterFaceText: "",
    waitingForPermission: "Aguardando permissão da câmera",
    moveCloserHeaderText: "Aproxime-se",
    moveCloserMessageText: "Por favor, aproxime-se da câmera.",
    photosensitivyWarningHeadingText: "Advertencia de fotosensibilidad",
    photosensitivyWarningBodyText:
      "Esta verificación muestra luces de colores. Tenga cuidado si es fotosensible.",
    goodFitCaptionText: "Buen ajuste",
    hintMoveFaceFrontOfCameraText: "Mova o rosto para a frente da câmera",
    hintTooManyFacesText: "Muitos rostos detectados",
    hintFaceDetectedText: "Rosto detectado",
    hintCanNotIdentifyText: "Não foi possível identificar",
    hintTooCloseText: "Muito perto",
    hintTooFarText: "Muito longe",
    hintConnectingText: "Conectando",
    hintVerifyingText: "Verificando",
    hintCheckCompleteText: "Verificação completa",
    hintIlluminationTooBrightText: "Iluminação muito forte",
    hintIlluminationTooDarkText: "Iluminação muito fraca",
    hintIlluminationNormalText: "Iluminação normal",
    hintHoldFaceForFreshnessText: "Fique parado",
    hintCenterFaceInstructionText: "Instrução para centralizar o rosto",
    hintFaceOffCenterText: "Rosto fora do centro",
    hintMatchIndicatorText: "Indicador de correspondência",
  };

  return (
    <div className="sm:w-[500px] sm:max-h-[500px] w-full max-h-[300px] sm:min-h-[200px] sm:shadow-lg rounded-lg sm:border sm:border-gray-200 p-2">
      {!sessionId ? (
        <Loader />
      ) : isLoading.isProcessing ? (
        <div className="flex flex-col justify-center items-center h-full">
          <Loader size="large" />
          <p className="text-gray-500">Processando...</p>
        </div>
      ) : faceAuthResults.success ? (
        <div className="flex flex-col justify-center items-center h-full gap-4 sm:h-[300px]">
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.5 30L27.5 35L37.5 25M52.5 30C52.5 42.4264 42.4264 52.5 30 52.5C17.5736 52.5 7.5 42.4264 7.5 30C7.5 17.5736 17.5736 7.5 30 7.5C42.4264 7.5 52.5 17.5736 52.5 30Z"
              stroke="#046C4E"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p className="text-gray-900 font-semibold text-xl">Sucesso!</p>
        </div>
      ) : faceAuthResults.failed ? (
        <div className="flex flex-col justify-center items-center h-full gap-4 sm:h-[200px]">
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25 25L35 35M35 25L25 35M52.5 30C52.5 42.4264 42.4264 52.5 30 52.5C17.5736 52.5 7.5 42.4264 7.5 30C7.5 17.5736 17.5736 7.5 30 7.5C42.4264 7.5 52.5 17.5736 52.5 30Z"
              stroke="#C81E1E"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p className="text-gray-900 font-semibold text-xl">Falha!</p>

          <button
            onClick={() => {
              window.location.reload();
            }}
            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full"
          >
            Tente novamente
          </button>
        </div>
      ) : (
        <ThemeProvider>
          <FaceLivenessDetector
            sessionId={sessionId}
            region="us-east-1"
            onAnalysisComplete={handleAnalysisComplete}
            onError={(error) => {
              console.error(error);
            }}
            config={{ credentialProvider: fetchCredentials }}
            displayText={portugueseDictionary}
            components={{
              PhotosensitiveWarning: () => {
                return <div></div>;
              },
            }}
          />
        </ThemeProvider>
      )}
    </div>
  );
};
