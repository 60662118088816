import api from ".";

export const startFaceAuth = async ({
  tenant,
  background_check_id,
  token,
  params_input,
  document,
}) => {
  return await api.post(
    `${tenant}/${background_check_id}/start_face_auth`,
    { params_input, document },
    {
      headers: {
        token: token,
      },
    }
  );
};

export const sendResults = async ({
  auth_token,
  tenant,
  background_check_id,
  background_check_execution_id,
  results,
}) => {
  return await api.post(
    `${tenant}/${background_check_id}/${background_check_execution_id}/done_face_auth`,
    results,
    {
      headers: {
        token: auth_token,
      },
    }
  );
};

export const getFaceAuthData = async ({ tenant, background_check_id }) => {
  return await api.get(
    `${tenant}/${background_check_id}/generate_data_face_auth`,
    {}
  );
};
