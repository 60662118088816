/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState } from "react";
import AWS from "aws-sdk";
import { useMutation } from "react-query";
import * as api from "../api/faceauth";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";

// Removed the import for OnboardingChannel as it is causing a module not found error
export const GlobalContext = createContext();

const credentials = window.location.href.includes(
  "https://face-auth.dev.proteo.com.br/"
)
  ? {
      bucket: "proteo-onboarding-dev",
      key: "AKIA3FLD6QU6N2QZKSU6",
      secret: "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",
    }
  : window.location.href.includes("https://face-auth.qa.proteo.com.br/")
  ? {
      bucket: "proteo-onboarding-qa",
      key: "AKIAXYKJRHXD2VEUK46H",
      secret: "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",
    }
  : {
      bucket: "proteo-onboarding-prod",
      key: "AKIAVRUVSPTEYFYNZX7M",
      secret: "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",
    };

const S3_BUCKET = credentials.bucket;
const REGION = "us-east-1";

AWS.config.update({
  region: REGION,
  credentials: new AWS.Credentials(credentials.key, credentials.secret),
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  return {
    document: params.get("document"),
    session_data: params.get("session_data"),
    tenant: params.get("tenant"),
    background_check_id: params.get("background_check_id"),
  };
}

// const { session_data, tenant, background_check_id } = getQueryParams();

const key = "7d6368fa7b7c06249cf484e3f2ea187238f70c7d166573c0f9544f4a0562fa25";

function encryptData(data) {
  try {
    return CryptoJS.AES.encrypt(data, key).toString();
  } catch (error) {
    console.log("Error encrypting data: ", error);
    return null;
  }
}

function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key);
  // console.log("bytes here", bytes);
  let decryptedData;
  try {
    decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    console.log("decryptedData here", decryptedData);
  } catch (error) {
    console.log("Error decrypting data: ", error);
    decryptedData = null;
  }
  return decryptedData;
}

const queryParams = getQueryParams();
const session_data = queryParams.session_data?.replace(/ /g, "+");

const token = session_data
  ? JSON.parse(decryptData(session_data)).auth_token
  : "0e98fa54-d581-4a7a-ad45-cc3f1aa06c49";

const tenant = session_data
  ? JSON.parse(decryptData(session_data)).tenant
  : queryParams.tenant || "teste100";

const background_check_id = queryParams.session_data
  ? JSON.parse(decryptData(session_data)).background_check_id
  : queryParams.background_check_id || "adc1db46-ac01-426e-87e1-107788389d6c";

export const GlobalProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState(null);
  const [faceLivenessResults, setFaceLivenessResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [startAuth, setStartAuth] = useState(false);
  const [faceAuthResults, setFaceAuthResults] = useState({});
  const [backgroundCheckExecutionId, setBackgroundCheckExecutionId] =
    useState(null);
  const [authData, setAuthData] = useState(null);

  const rekognition = new AWS.Rekognition();

  const params_input = {};
  const urlParams = new URLSearchParams(window.location.search);
  for (const [key, value] of urlParams) {
    if (
      key !== "tenant" &&
      key !== "background_check_id" &&
      key !== "document"
    ) {
      params_input[key] = value;
    }
  }

  const startFaceAuthMutation = useMutation({
    mutationFn: (data) =>
      api.startFaceAuth({
        tenant,
        background_check_id,
        token: data.token,
        document: data.document,
        params_input,
      }),
    onSuccess: (res) => {
      setStartAuth(true);
      setBackgroundCheckExecutionId(
        res.data.data.background_check_execution_id
      );
      startFaceLivenessSession();
    },
    onError: ({
      response: {
        data: { message, error },
      },
    }) => {
      toast.error(error);
    },
    // enabled: !queryParams.session_data,
  });

  const getFaceAuthDataMutation = useMutation({
    mutationFn: () => api.getFaceAuthData({ tenant, background_check_id }),
    onSuccess: (res) => {
      setAuthData(res.data.data);
      startFaceAuthMutation.mutate({
        tenant,
        background_check_id,
        token: res.data.data.auth_token,
        document: queryParams.document || "80150887981",
      });
    },
  });

  const getFaceAuthData = async () => {
    await getFaceAuthDataMutation.mutateAsync({ tenant, background_check_id });
  };

  const startFaceLivenessSession = async () => {
    const params = {
      // Add other necessary parameters if needed
    };

    try {
      const response = await rekognition
        .createFaceLivenessSession(params)
        .promise();
      const sessionId = response.SessionId; // Extract the session ID
      setSessionId(sessionId);
      return sessionId; // Return the session ID
    } catch (error) {
      console.error("Error starting face liveness session:", error);
      throw error; // Handle the error accordingly
    }
  };

  const handleAnalysisComplete = async () => {
    setLoading(true);
    console.log("handleAnalysisComplete", sessionId);
    try {
      const response = await rekognition
        .getFaceLivenessSessionResults({
          SessionId: sessionId,
        })
        .promise();
      const data = response;
      setFaceLivenessResults(data);
      uploadImagesToS3(data);
    } catch (error) {
      console.error("Error during analysis:", error);
      setLoading(false);
    }
  };

  const fetchCredentials = async () => {
    return AWS.config.credentials;
  };

  const sendResultsMutation = useMutation({
    mutationFn: (data) =>
      api.sendResults({
        ...data,
        auth_token: authData.auth_token,
      }),
    onSuccess: (res) => {
      console.log("res gerw", res);
      setFaceAuthResults({ ...res.data.data, success: true });
      // window.location.href = "https://onboarding.dev.proteo.com.br/";
      const { redirect_to } = res.data.data;
      if (redirect_to) {
        const redirectUrl = `${redirect_to}`;
        const url = redirectUrl.match(/^https?:/)
          ? redirectUrl
          : "//" + redirectUrl;

        window.location.href = url;
      }
      setLoading(false);
    },
    onError: ({ error }) => {
      toast.error(error);
      setLoading(false);
      setFaceAuthResults({ success: false, failed: true });
    },
  });

  const sendResults = async (results) => {
    await sendResultsMutation.mutateAsync({
      tenant,
      background_check_id,
      background_check_execution_id: backgroundCheckExecutionId,
      results: {
        ...results,
        document: queryParams.document || "80150887981",
      },
    });
  };

  const uploadImagesToS3 = async (data) => {
    const background_check_execution_id =
      backgroundCheckExecutionId ||
      `${Date.now()}-${Math.floor(Math.random() * 1000000)}`;

    const byteArray = new Uint8Array(data.ReferenceImage.Bytes);

    const livenessImage = new Blob([byteArray], { type: "image/jpeg" });
    const files = [livenessImage];
    let results = {};

    const uploadPromises = files.map(async (file, i) => {
      const uniqueKey = `${tenant}/doc-img-f_${background_check_execution_id}.jpeg`;
      const params = {
        Body: file,
        Bucket: S3_BUCKET,
        Key: uniqueKey,
      };

      try {
        await myBucket.putObject(params).promise();
        const s3Url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${params.Key}`;
        const updateResults = [
          () =>
            (results.liveness = {
              ...data,
              ReferenceImage: { Bytes: "", image_url: s3Url },
            }),
        ];

        if (updateResults[i]) {
          updateResults[i]();
        }
      } catch (err) {
        setLoading(false);
        console.log("Error uploading file:", file.name, err);
        throw err;
      }
    });

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      setLoading(false);
      console.error("Error uploading images to S3:", error);
      throw error;
    }

    sendResults(results);
  };

  const handleImageUpload = (event, setImageUrl, setImageName) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
        setImageName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const isLoading = { isProcessing: loading };

  return (
    <GlobalContext.Provider
      value={{
        isLoading,
        sessionId,
        faceLivenessResults,
        faceAuthResults,
        startAuth,
        setStartAuth,
        getFaceAuthData,
        handleImageUpload,
        startFaceLivenessSession,
        handleAnalysisComplete,
        fetchCredentials,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
