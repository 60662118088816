import logo from "./logo.svg";
import "./App.css";
import { Liveness } from "./component/liveness";
import { GlobalContext } from "./context/GlobalContext";
import { useContext } from "react";

function App() {
  const { startAuth, setStartAuth, getFaceAuthData } = useContext(
    GlobalContext
  );
  return (
    <div className="w-full h-screen flex items-center justify-center overflow-hidden">
      {startAuth ? (
        <Liveness />
      ) : (
        <div className="gap-4 sm:w-[500px] sm:max-h-[500px] w-full max-h-[300px] sm:min-h-[200px] sm:shadow-lg rounded-lg sm:border sm:border-gray-200 p-4 flex flex-col items-center justify-center">
          <p className="text-gray-900 font-semibold text-xl text-center">
            Vamos começar o reconhecimento facial!
          </p>

          <svg
            width="180"
            height="180"
            viewBox="0 0 180 180"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="180" height="180" rx="90" fill="#F9FAFB" />
            <path
              d="M30 62.9128V51.6123H41.3004"
              stroke="#0E9F6E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M150 62.9128V51.6123H138.7"
              stroke="#0E9F6E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M30 117.088V128.389H41.3004"
              stroke="#0E9F6E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M150 117.088V128.389H138.7"
              stroke="#0E9F6E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M67.1666 78.3337V72.5003C67.1666 70.9532 67.7812 69.4695 68.8752 68.3755C69.9691 67.2816 71.4529 66.667 73 66.667H78.8333M67.1666 101.667V107.5C67.1666 109.047 67.7812 110.531 68.8752 111.625C69.9691 112.719 71.4529 113.334 73 113.334H78.8333M102.167 66.667H108C109.547 66.667 111.031 67.2816 112.125 68.3755C113.219 69.4695 113.833 70.9532 113.833 72.5003V78.3337M102.167 113.334H108C109.547 113.334 111.031 112.719 112.125 111.625C113.219 110.531 113.833 109.047 113.833 107.5V101.667M81.75 84.167H81.7791M99.25 84.167H99.2791M83.2083 98.7503C84.1588 99.7204 85.2933 100.491 86.5453 101.017C87.7974 101.543 89.1418 101.814 90.5 101.814C91.8581 101.814 93.2025 101.543 94.4546 101.017C95.7066 100.491 96.8411 99.7204 97.7916 98.7503"
              stroke="#4B5563"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="32.5"
              y="86.0879"
              width="114"
              height="8"
              rx="4"
              fill="#0E9F6E"
              fill-opacity="0.1"
            />
            <path
              d="M36.5 90.0879H142.5"
              stroke="#0E9F6E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p className="text-gray-500  text-sm text-center">
            Precisamos ativar a câmera para verificar sua identidade.
          </p>

          <button
            onClick={() => {
              getFaceAuthData();
            }}
            className="bg-blue-700 text-white px-4 py-2 rounded-md w-full"
          >
            Abrir Câmera
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
