import axios from "axios";
import CryptoJS from "crypto-js";

const key = "7d6368fa7b7c06249cf484e3f2ea187238f70c7d166573c0f9544f4a0562fa25";

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  return {
    session_data: params.get("session_data")?.replace(/ /g, "+"),
  };
}

function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key);
  let decryptedData;
  try {
    decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    decryptedData = null;
  }
  return decryptedData;
}

const { session_data } = getQueryParams();

const authData = session_data ? JSON.parse(decryptData(session_data)) : null;
console.log("authData", authData?.auth_token);

const baseURL = window.location.href.includes(
  "https://face-auth.dev.proteo.com.br"
)
  ? "https://dev.proteo.com.br/api/v1/"
  : window.location.href.includes("https://face-auth.qa.proteo.com.br")
  ? "https://qa.proteo.com.br/api/v1/"
  : window.location.href.includes("https://face-auth.proteo.com.br")
  ? "https://proteo.com.br/api/v1/"
  : "http://localhost:3005/api/v1/";

const api = axios.create({ baseURL });

api.interceptors.request.use(function(config) {
  if (authData?.auth_token) {
    config.headers.token = authData.auth_token;
  }
  return config;
});

export default api;
